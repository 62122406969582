<template>
    <modal id="member-modal"
           v-model="showModal"
           :title="!selectedUrl.includes('/edit') ? $t('move_member') : generateModalTitle('member')"
           @submit="submitData" :loading="loading"
           :preloader="preloader">

        <form
            ref="form"
            :data-url='!selectedUrl.includes("/edit") ? selectedUrl : `${TENANT_BASE_URL}app/hotel/member`'
            @submit.prevent="submitData"
        >
            <app-form-group-selectable
                v-if="!selectedUrl.includes('/edit')"
                type="select"
                :label="$t('hotel')"
                list-value-field="name"
                :chooseLabel="$t('hotel')"
                v-model="formData.new_hotel"
                :required="true"
                :error-message="$errorMessage(errors, 'new_hotel')"
                :fetch-url="`${TENANT_BASE_URL}selectable/hotel/list`"
            />

            <app-form-group-selectable
                v-if="selectedUrl.includes('/edit')"
                type="multi-select"
                :label="$t('leader')"
                list-value-field="full_name"
                :chooseLabel="$t('leader')"
                v-model="formData.leaders"
                :required="true"
                :error-message="$errorMessage(errors, 'leaders')"
                :fetch-url="`${apiUrl.TENANT_SELECTABLE_DEPARTMENT_USERS}?without=admin&member=only&with_auth=yes`"
            />

            <app-form-group-selectable
                v-if="selectedUrl.includes('/edit')"
                :label="$fieldLabel('members', '')"
                type="multi-select"
                v-model="formData.members"
                list-value-field="full_name"
                :required="true"
                :error-message="$errorMessage(errors, 'members')"
                :fetch-url="`${apiUrl.TENANT_SELECTABLE_DEPARTMENT_USERS}?without=admin&member=only&with_auth=yes`"
            />
        </form>
    </modal>
</template>

<script>
import FormHelperMixins from "../../../../../common/Mixin/Global/FormHelperMixins";
import ModalMixin from "../../../../../common/Mixin/Global/ModalMixin";
import {formDataAssigner} from "../../../../../common/Helper/Support/FormHelper";
import {TENANT_BASE_URL} from "../../../../../common/Config/UrlHelper";

export default {
    name  : "MemberCreateEditModal",
    mixins: [FormHelperMixins, ModalMixin],
    props : {
        hotel: {
            type: Object,
        },
    },
    data() {
        return {
            TENANT_BASE_URL,
            formData: {
                members: [],
                leaders: [],
            },
        }
    },
    methods: {
        submitData() {
            this.loading          = true
            this.formData.leaders = JSON.stringify(this.formData.leaders);
            this.formData.members = JSON.stringify(this.formData.members);

            let url      = this.$refs.form.dataset["url"];
            let formData = formDataAssigner(new FormData, this.formData);

            if (!this.selectedUrl.includes("/edit")) {
                // for file update need to send by post.
                formData.append('_method', 'PATCH');
            }

            formData.append('hotel_id', this.hotel.id);

            this.submitFromFixin('post', url, formData);
        },
        afterSuccess({data}) {
            this.formData = {};
            $('#member-modal').modal('hide');
            this.$emit('input', false);
            this.toastAndReload(data.message, 'member-table');
        },
        afterSuccessFromGetEditData(response) {
            this.preloader = false;
            this.formData  = response.data;
        },
    },
}
</script>

