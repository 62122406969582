<template>
    <div>
        <app-page-top-section :title="$t('member')">
            <app-default-button
                :title="$fieldTitle('update', 'member', true)"
                v-if="$can('create_hotel_member')"
                @click="openModal()"
            />
        </app-page-top-section>

        <app-table
            id="member-table"
            :options="options"
            @action="triggerActions"
        />

        <app-hotel-member-create
            v-if="isModalActive"
            v-model="isModalActive"
            :selected-url="memberUrl"
            :hotel="tab.hotel"
            @close="isModalActive = false"
        />

        <app-confirmation-modal
            v-if="confirmationModalActive"
            icon="trash-2"
            modal-id="app-confirmation-modal"
            @confirmed="confirmed('member-table')"
            @cancelled="cancelled"
        />
    </div>
</template>

<script>
import FormHelperMixins from "../../../../../common/Mixin/Global/FormHelperMixins";
import {TENANT_BASE_URL} from "../../../../../common/Config/UrlHelper";
import HotelMemberMixins from "../../../Mixins/HotelMemberMixins";

export default {
    name  : "HotelMember",
    mixins: [FormHelperMixins, HotelMemberMixins],
    props : {
        id: {
            type: String
        }
    },
    data() {
        return {
            tab          : this.$parent.$props.tabs.find(e => e.component === 'app-hotel-member'),
            isModalActive: false,
            memberUrl    : '',
        }
    },
    methods: {
        triggerActions(row, action, active) {
            if (action.title === this.$t('move_member')) {
                this.memberUrl     = `${TENANT_BASE_URL}app/hotel/member/${row.id}`;
                this.isModalActive = true;
            } else {
                this.getAction(row, action, active)
            }
        },
        openModal() {
            this.isModalActive = true;
            this.memberUrl     = `${TENANT_BASE_URL}app/hotel/member/${this.tab.hotel.id}/edit`;
        },
    },
}
</script>