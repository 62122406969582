<template>
    <modal id="house-keeping-modal"
           size="large"
           v-model="showModal"
           :title="generateModalTitle('house_keeping')"
           @submit="submitData" :loading="loading"
           :preloader="preloader">

        <form
            ref="form"
            :data-url='selectedUrl ? selectedUrl : `${TENANT_BASE_URL}app/hotel/house-keeping`'
            @submit.prevent="submitData"
        >
            <div class="row">
                <div v-if="!hotel_id" class="col-md-12">
                    <app-form-group-selectable
                        type="search-select"
                        :label="$t('hotel')"
                        list-value-field="name"
                        v-model="formData.hotel_id"
                        :chooseLabel="$t('hotel')"
                        :error-message="$errorMessage(errors, 'hotel_id')"
                        :fetch-url="`${TENANT_BASE_URL}selectable/hotel/list`"
                    />
                </div>
                <div class="col-md-12">
                    <app-form-group-selectable
                        type="multi-select"
                        :label="$t('maid')"
                        list-value-field="employee_name"
                        v-model="formData.maid_ids"
                        :chooseLabel="$t('maid')"
                        :error-message="$errorMessage(errors, 'maid_ids')"
                        :fetch-url="member_url"
                    />
                </div>
                <div class="col-md-6">
                    <app-form-group
                        type="date"
                        :label="$t('date')"
                        v-model="formData.date"
                        :placeholder="formData.date ? $placeholder('date') : this.$t('not_added_yet')"
                        :error-message="$errorMessage(errors, 'date')"
                    />
                </div>
                <div class="col-md-6">
                    <app-form-group-selectable
                        type="search-select"
                        :label="$t('room')"
                        list-value-field="name"
                        v-model="formData.room_id"
                        :chooseLabel="$t('room')"
                        :error-message="$errorMessage(errors, 'room_id')"
                        :fetch-url="room_url"
                        @change="updatePrice()"
                    />
                </div>
                <div class="col-md-6">
                    <app-form-group-selectable
                        type="select"
                        :label="$t('staying')"
                        list-value-field="name"
                        v-model="formData.staying_id"
                        :chooseLabel="$t('staying')"
                        :error-message="$errorMessage(errors, 'staying_id')"
                        :fetch-url="`${TENANT_BASE_URL}selectable/hotel/staying`"
                    />
                </div>
                <div class="col-md-6">
                    <app-form-group-selectable
                        type="select"
                        :label="$t('cleaning')"
                        list-value-field="name"
                        v-model="formData.cleaning_id"
                        :chooseLabel="$t('cleaning')"
                        :error-message="$errorMessage(errors, 'cleaning_id')"
                        :fetch-url="`${TENANT_BASE_URL}selectable/hotel/cleaning`"
                        @change="updatePrice()"
                    />
                </div>
                <div class="col-md-6">
                    <app-form-group-selectable
                        type="select"
                        :label="$t('status')"
                        list-value-field="name"
                        v-model="formData.status_id"
                        :chooseLabel="$t('status')"
                        :error-message="$errorMessage(errors, 'status_id')"
                        :fetch-url="house_keeping_status_url"
                    />
                </div>
                <div class="col-md-6">

                </div>
                <div class="col-md-6">
                    <app-form-group
                        type="time"
                        v-model="formData.start_time"
                        :label="$t('start_time')"
                        :error-message="$errorMessage(errors, 'start_time')"
                        :placeholder="$t('enter_start_time')"
                    />
                </div>
                <div class="col-md-6">
                    <app-form-group
                        type="time"
                        v-model="formData.end_time"
                        :label="$t('end_time')"
                        :error-message="$errorMessage(errors, 'end_time')"
                        :placeholder="$t('enter_end_time')"
                    />
                </div>
                <div class="col-md-6">
                    <app-form-group
                        type="number"
                        :label="$t('contract_price')"
                        :placeholder="$placeholder('contract_price')"
                        v-model="formData.contract_price"
                        :error-message="$errorMessage(errors, 'contract_price')">
                    </app-form-group>
                </div>
                <div class="col-md-6">
                    <app-form-group
                        type="number"
                        :label="$t('selling_price')"
                        :placeholder="$placeholder('selling_price')"
                        v-model="formData.selling_price"
                        :error-message="$errorMessage(errors, 'selling_price')">
                    </app-form-group>
                </div>
                <div class="col-md-12">
                    <div class="form-group">
                        <div class="table-responsive">
                            <table class="table">
                                <thead class="table-light">
                                <tr>
                                    <th style="min-width: 150px;">{{ $t('option') }}</th>
                                    <th style="min-width: 100px;">{{ $t('quantity') }}</th>
                                    <th style="display:none;min-width: 150px;">{{ $t('contract_price') }}</th>
                                    <th style="min-width: 150px;">{{ $t('selling_price') }}</th>
                                    <th style="min-width: 50px;" class="text-end text-nowrap">{{ $t('action') }}</th>
                                </tr>
                                </thead>
                                <tbody>
                                <template v-if="options">
                                    <tr v-for="(option, index) in options">
                                        <td>
                                            <input type="hidden" v-model="option.name">
                                            <app-form-group
                                                type="select"
                                                :label="$t('option')"
                                                list-value-field="name"
                                                v-model="option.id"
                                                :chooseLabel="$t('option')"
                                                :list="optionList"
                                                @change="changeOption(index, $event.target.value)"
                                            />
                                        </td>
                                        <td>
                                            <app-form-group
                                                type="number"
                                                :label="$t('quantity')"
                                                :placeholder="$placeholder('quantity')"
                                                @change="changeQuantity(index, $event.target.value)"
                                                v-model="option.quantity">
                                            </app-form-group>
                                        </td>
                                        <td class="d-none">
                                            <app-form-group
                                                type="number"
                                                :label="$t('contract_price')"
                                                :placeholder="$placeholder('contract_price')"
                                                v-model="option.contract_price">
                                            </app-form-group>
                                        </td>
                                        <td>
                                            <app-form-group
                                                type="number"
                                                :label="$t('selling_price')"
                                                :placeholder="$placeholder('selling_price')"
                                                v-model="option.selling_price">
                                            </app-form-group>
                                        </td>
                                        <td class="text-center">
                                            <a href="#" @click="deleteOption(index)" class="btn text-danger px-0">
                                                <app-icon name="trash-2"/>
                                            </a>
                                        </td>
                                    </tr>
                                    <tr v-if="options.length === 0">
                                        <td colspan="100">{{ $t('No data found.') }}</td>
                                    </tr>
                                </template>
                                </tbody>
                            </table>
                        </div>
                        <button type="button" class="btn btn-sm btn-primary" @click="addOption">
                            {{ $t('Add new') }}
                        </button>
                    </div>
                </div>
                <div class="col-md-12">
                    <app-form-group
                        type="textarea"
                        label-alignment="top"
                        :label="$t('remark')"
                        :placeholder="$textAreaPlaceHolder('remark')"
                        v-model="formData.remark"
                        :error-message="$errorMessage(errors, 'remark')">
                    </app-form-group>
                </div>
            </div>
        </form>
    </modal>
</template>

<style>
.form-group table td > .form-group > label {
    display : none;
}

.form-group table td > .form-group {
    margin-bottom : 0;
}
</style>

<script>
import FormHelperMixins from "../../../../../common/Mixin/Global/FormHelperMixins";
import ModalMixin from "../../../../../common/Mixin/Global/ModalMixin";
import {formDataAssigner} from "../../../../../common/Helper/Support/FormHelper";
import {TENANT_BASE_URL} from "../../../../../common/Config/UrlHelper";
import {
    formatDateForServer,
    formatTimeForServer,
    today
} from "../../../../../common/Helper/Support/DateTimeHelper";
import {axiosGet} from "../../../../../common/Helper/AxiosHelper";

export default {
    name  : "HouseKeepingCreateEditModal",
    mixins: [FormHelperMixins, ModalMixin],
    props : {
        hotel_id: {
            default: null
        },
    },
    data() {
        return {
            TENANT_BASE_URL,
            optionList              : [],
            options                 : [],
            room_url                : null,
            member_url              : null,
            house_keeping_status_url: null,
            formData                : {
                hotel_id      : this.hotel_id ? this.hotel_id : null,
                date          : formatDateForServer(today()),
                contract_price: 0,
                selling_price : 0,
            },
        }
    },
    watch  : {
        'formData.hotel_id': {
            handler  : function (value) {
                const hotel_id                = value && typeof value !== "undefined" ? value : 0
                this.room_url                 = `${TENANT_BASE_URL}selectable/hotel/room?hotel_id=${hotel_id}`
                this.member_url               = `${TENANT_BASE_URL}selectable/hotel/member?hotel_id=${hotel_id}`
                this.house_keeping_status_url = `${TENANT_BASE_URL}selectable/hotel/house-keeping-status?hotel_id=${hotel_id}`

                axiosGet(`${TENANT_BASE_URL}selectable/hotel/option?hotel_id=${hotel_id}`).then(({data}) => {
                    this.optionList = data
                }).catch((error) => {
                    this.$toastr.e(error.response.data.message)
                }).finally(() => {
                    this.errors = {};
                })
            },
            immediate: true
        },
    },
    methods: {
        submitData() {
            this.loading   = true
            const url      = this.$refs.form.dataset["url"];
            const formData = formDataAssigner(new FormData, this.formData);

            if (this.formData.start_time) {
                formData.set('start_time', formatTimeForServer(this.formData.start_time))
            }
            if (this.formData.end_time) {
                formData.set('end_time', formatTimeForServer(this.formData.end_time))
            }
            if (this.formData.maid_ids) {
                formData.set('maid_ids', JSON.stringify(this.formData.maid_ids))
            }
            if (this.options.length > 0) {
                formData.set('options', JSON.stringify(this.options))
            }

            formData.set('date', formatDateForServer(this.formData.date))

            if (this.selectedUrl) {
                // for file update need to send by post.
                formData.append('_method', 'PATCH');
            }

            if (this.hotel_id) {
                formData.append('hotel_id', this.hotel_id);
            }

            this.submitFromFixin('post', url, formData);
        },
        afterSuccess({data}) {
            this.formData = {
                hotel_id      : this.hotel_id ? this.hotel_id : null,
                date          : formatDateForServer(today()),
                contract_price: 0,
                selling_price : 0,
            };
            $('#house-keeping-modal').modal('hide');
            this.$emit('input', false);
            this.toastAndReload(data.message, 'house-keeping-table');
        },
        afterSuccessFromGetEditData(response) {
            this.preloader = false;
            this.formData  = response.data;
            this.options   = response.data.options || [];
        },
        updatePrice() {
            if (this.formData.room_id && this.formData.cleaning_id && this.formData.hotel_id) {
                axiosGet(`${TENANT_BASE_URL}selectable/hotel/pricing?hotel_id=${this.formData.hotel_id}&room_id=${this.formData.room_id}&cleaning_id=${this.formData.cleaning_id}`)
                .then(({data}) => {
                    if (data.length && data[0].contract_price) {
                        this.formData.contract_price = parseInt(data[0].contract_price)
                        this.formData.selling_price  = parseInt(data[0].selling_price)
                    } else {
                        this.formData.contract_price = 0
                        this.formData.selling_price  = 0
                    }
                })
                .catch(error => this.$toastr.e(error.response.data.message))
            } else {
                this.formData.contract_price = 0
                this.formData.selling_price  = 0
            }
        },
        deleteOption(index) {
            this.options.splice(index, 1)
        },
        addOption() {
            this.options.push({
                id            : null,
                name          : null,
                quantity      : 1,
                contract_price: 0,
                selling_price : 0,
            })
        },
        changeQuantity(index, value) {
            if (this.options[index].id) {
                this.changeOption(index, this.options[index].id)
            }
        },
        changeOption(index, value) {
            const hasOption = this.options.filter(e => e.id === value)

            if (hasOption.length > 1) {
                this.options[index].id             = 0
                this.options[index].name           = null
                this.options[index].contract_price = 0
                this.options[index].selling_price  = 0
            } else {
                axiosGet(`${TENANT_BASE_URL}selectable/hotel/option?id=${value}`).then(({data}) => {
                    if (data.id) {
                        this.options[index].contract_price = parseInt(data.contract_price) * this.options[index].quantity
                        this.options[index].selling_price  = parseInt(data.selling_price) * this.options[index].quantity
                        this.options[index].name           = data.name
                    } else {
                        this.options[index].contract_price = 0
                        this.options[index].selling_price  = 0
                        this.options[index].name           = null
                    }
                }).catch(error => {
                    this.$toastr.e(error.response.data.message)
                })
            }
        },
    },
}
</script>

